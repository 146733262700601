<template>
  <div class="wrapper_404">
    <span> 404 </span>
    <div style="">страница не найдена</div>
    <router-link to="/">на главную</router-link>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style lang="scss" scoped>
.wrapper_404 {
  text-align: center;
  margin-top: 10%;

  :nth-child(1) {
    font-size: 45px;
    opacity: .65;
  }

  :nth-child(2) {
    font-size: 16px;
    opacity: .65;
    margin-bottom: 20px
  }
}
</style>